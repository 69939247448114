import angular from 'angular';
import 'ng-redux';

import template from './golfleet-toolbar.html';
import './golfleet-toolbar.scss';

class GolfleetToolbarController {
  static get $inject() {
    return ['$element', '$ngRedux', '$state', '$transclude', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $state, $transclude, $http, urlApi) {
    Object.assign(this, { $: $element[0], $ngRedux, $state, $transclude, $http, urlApi });

    this.__golfleetBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        stateConfig: currentState ? currentState.stateConfig : {},
        currentState: currentState || {},
      });
    })(this);

    this.slots = {
      hasHeader: this.$transclude.isSlotFilled('headerSlot'),
      hasRight: this.$transclude.isSlotFilled('rightSlot'),
      hasLeft: this.$transclude.isSlotFilled('leftSlot'),
    };

    if (this.slots.hasRight) {
      this.$.setAttribute('has-right-slot', '');
    }
    if (this.slots.hasLeft) {
      this.$.setAttribute('has-left-slot', '');
    }
  }

  /* Lifecycle */
  $onInit() {}

  $onDestroy() {
    this.__golfleetBehavior();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  /* */

  /* Observers */
  /* */
}

class GolfleetToolbar {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.transclude = {
      headerSlot: '?headerSlot',
      leftSlot: '?leftSlot',
      rightSlot: '?rightSlot',
    };
    this.controller = GolfleetToolbarController;
  }
}

angular.module('golfleet-toolbar', ['ngRedux']).component('golfleetToolbar', new GolfleetToolbar());
