import { app } from './app.module';

app
  .constant('version', 'process.app.version')
  .constant('mobile', false)
  .constant('urlApi', 'process.app.apiUrl')
  .constant('tipoApp', 24)
  .constant('playStore', 'process.app.playStore')
  .constant(
    'isProgressiveWebApp',
    window.sessionStorage.getItem('utmSource') === 'progressive-web-app',
  )
  .constant(
    'isTrustedWebActivity',
    window.sessionStorage.getItem('utmSource') === 'trusted-web-activity',
  )
  .constant('_months', [
    { id: 1, desc: 'Janeiro' },
    { id: 2, desc: 'Fevereiro' },
    { id: 3, desc: 'Março' },
    { id: 4, desc: 'Abril' },
    { id: 5, desc: 'Maio' },
    { id: 6, desc: 'Junho' },
    { id: 7, desc: 'Julho' },
    { id: 8, desc: 'Agosto' },
    { id: 9, desc: 'Setembro' },
    { id: 10, desc: 'Outubro' },
    { id: 11, desc: 'Novembro' },
    { id: 12, desc: 'Dezembro' },
  ])
  .constant('_today', new Date())
  .constant('_fullYear', new Date().getFullYear())
  .constant('_authConfig', {
    authority: 'process.app.authorityUrl',
    client_id: 'driver.golfleet.com.br',
    client_secret: 'process.app.clientSecret',
    redirect_uri: 'process.app.redirectUrl',
    response_type: 'code',
    scope: 'openid profile driver',
    post_logout_redirect_uri: 'process.app.logoutRedirectUrl',
    silent_redirect_uri: 'process.app.silentRenewUrl',
    automaticSilentRenew: true,
    loadUserInfo: true,
    monitorSession: true,
  });
