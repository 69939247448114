import angular from 'angular';

import template from './golfleet-card-ranking.html';
import './golfleet-card-ranking.scss';

class GolfleetCardRankingController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$state', '$http', 'urlApi', '$ngRedux'];
  }

  constructor($element, $scope, $rootScope, $state, $http, urlApi, $ngRedux) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $rootScope,
      $state,
      $http,
      urlApi,
      $ngRedux,
    });

    $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        userName: behavior.session.userName,
        companyName: behavior.session.companyName,
      }),
    )(this);

    this.status = 0;
  }

  $onInit() {
    this._getRanking();
  }

  _getDateRanking() {
    const date = new Date(this.data.date);
    return `${date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      month: 'long',
    })} de ${date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', year: 'numeric' })}`;
  }

  _getRanking() {
    this.$http({
      url: `${this.urlApi}/Ranking/GetRanking`,
      method: 'POST',
    }).then(
      success => {
        if (success.status && success.status != 200) {
          this.status = 2;
          return;
        }
        this.data = success.data.data;
        this.status = 1;
      },
      () => {
        this.status = 2;
      },
    );
  }

  _iterador(num) {
    return Array(num);
  }

  _getGoodStats() {
    return this._iterador(this.data.stars);
  }

  _getBadtats() {
    return this._iterador(5 - this.data.stars);
  }

  _goToRanking() {
    this.$state.go('rankingReport', { reload: true });
  }
}

class GolfleetCardRanking {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetCardRankingController;
  }
}

angular
  .module('golfleet-card-ranking', [])
  .component('golfleetCardRanking', new GolfleetCardRanking());
