import angular from 'angular';
import 'ng-redux';
import moment from 'moment/src/moment';

import { GolfleetReportController } from '../golfleet-report/golfleet-report';

import template from './golfleet-report-ranking.html';
import './golfleet-report-ranking.scss';

class GolfleetReportRankingController extends GolfleetReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
    );
    Object.assign(this, {
      $: $element[0],
      $scope,
      $rootScope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
    });

    this.status = 0;

    moment.locale('pt-BR');
  }

  $onInit() {
    this._getData();
  }

  _getData() {
    this.status = 0;
    this.$http({
      url: `${this.urlApi}/${this.stateConfig.getDataMethod}`,
      method: 'POST',
    }).then(
      success => {
        if (success.status && success.status != 200) {
          return;
        }
        this.months = success.data.data;
        if (this.months) {
          this.selectedMonth = this.months[this.months.length - 1];
          setTimeout(() => {
            this._moveToEnd();
          }, 50);
        }
        this.status = 1;
      },
      () => {
        this.status = 2;
      },
    );
  }

  _selectMonth(month) {
    this.selectedMonth = this.months.find(
      m => new Date(this._toDate(m.month)).getMonth() == new Date(this._toDate(month)).getMonth(),
    );
  }

  _moveToEnd() {
    const months = document.querySelector('#months');
    months.scrollLeft = months.scrollWidth;
  }

  _iterador(num) {
    return Array(num);
  }

  _getGoodStats(index) {
    return this._iterador(this.selectedMonth.rankings[index].stars);
  }

  _getBadtats(index) {
    return this._iterador(5 - this.selectedMonth.rankings[index].stars);
  }

  _getMonth(date) {
    return new Date(date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', month: 'long' });
  }

  _getYear(date) {
    return new Date(date).toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
    });
  }

  _toDate(date) {
    return moment(date).utcOffset(+3)._d;
  }
}

class GolfleetReportRanking {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportRankingController;
  }
}

angular
  .module('golfleet-report-ranking', ['ngRedux', 'ng-tippy', 'golfleet-loader', 'golfleet-toast'])
  .component('golfleetReportRanking', new GolfleetReportRanking());
