import angular from 'angular';
import 'ng-redux';

import '../golfleet-dropdown/golfleet-dropdown';
import '../golfleet-popup/golfleet-popup';
import '../golfleet-toast/golfleet-toast';

import template from './golfleet-user-menu.html';
import './golfleet-user-menu.scss';

class GolfleetUserMenuController {
  static get $inject() {
    return ['$element', '$ngRedux', '$location', 'authServices', 'commonServices', '_authConfig'];
  }

  constructor($element, $ngRedux, $location, authServices, commonServices, _authConfig) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $location,
      authServices,
      commonServices,
      _authConfig,
    });

    this.__golfleetBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        token: behavior.session.token,
        userName: behavior.session.userName,
        companyName: behavior.session.companyName,
        otpClientRequest: behavior.session.otpClientRequest,
        otpActive: behavior.session.otpActive,
        otpBlockSwitch: behavior.session.otpBlockSwitch,
      }),
    )(this);

    this.toastText = '';
  }

  /* Lifecycle */
  $onInit() {
    this.$.addEventListener('showToastAccountPopup', this._showToast.bind(this));
  }

  $onDestroy() {
    this.__golfleetBehavior();
  }
  /* */

  signout() {
    this.authServices.setLogout();
  }

  _redefinePassword() {
    this.$.querySelector('#popup-confirmation').toggle();
  }

  _showToast(evt) {
    this.toastText = evt.detail.message;
    this.$.querySelector('golfleet-toast#toast-user-menu').toggle(true);
  }

  _evtClickBtnPopup(type) {
    if (type == 'close') {
      this.$.querySelector('#popup-confirmation').toggle();
    } else if (type == 'primaryBtn') {
      this.commonServices.resetPassword().then(response => {
        if (response.status === 200) {
          this.$.querySelector('#popup-confirmation').toggle();
          this.authServices.setLogout();
        } else {
          this.toastText = 'Não foi possivel redefinir a senha!';
          this.$.querySelector('golfleet-toast#toast-user-menu').toggle(true);
        }
      });
    }
  }

  _openOtpPopup() {
    if (!this.otpBlockSwitch) {
      const returnUrl = encodeURIComponent(window.location.href); // URL atual
      const identityServerUrl = `${
        this._authConfig.authority
      }Account/CreateOtp?returnUrl=${returnUrl}&clientId=${encodeURIComponent(
        this._authConfig.client_id,
      )}`;
      window.location.href = identityServerUrl;
    }
  }
}

class GolfleetUserMenu {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetUserMenuController;
  }
}
angular
  .module('golfleet-user-menu', [
    'ngRedux',
    'golfleet-dropdown',
    'golfleet-toast',
    'golfleet-popup',
  ])
  .component('golfleetUserMenu', new GolfleetUserMenu());
