import angular from 'angular';
import { app } from '../app.module';

class AuthInterceptor {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return [
      '$state',
      '$ngRedux',
      '$q',
      '$location',
      'version',
      'tipoApp',
      'mobile',
      'authServices',
      '_authConfig',
    ];
  }

  constructor(
    $state,
    $ngRedux,
    $q,
    $location,
    version,
    tipoApp,
    mobile,
    authServices,
    _authConfig,
  ) {
    Object.assign(this, {
      $state,
      $ngRedux,
      $q,
      $location,
      version,
      tipoApp,
      mobile,
      authServices,
      _authConfig,
    });

    this.__golfleetStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);

    this.request = this.request.bind(this);
    this.response = this.response.bind(this);
    this.responseError = this.responseError.bind(this);
  }

  request(request) {
    Object.assign(request, {
      headers: request.headers || {},
      params: request.params || {},
    });

    if (this.session.token && request.url.indexOf('api.here.com') == -1) {
      request.headers.Authorization = `Bearer ${this.session.token}`;
    }
    if (request.url.indexOf('api.here.com') != -1) return request;
    if (request.method == 'POST' && request.url.indexOf(this._authConfig.authority) == -1) {
      if (request.data) {
        const configData = angular.fromJson(request.data);
        Object.assign(configData, {
          versao: this.version,
          mobile: this.mobile,
          tipoApp: this.tipoApp,
        });
        request.data = angular.toJson(configData);
      } else
        request.data = angular.toJson({
          versao: this.version,
          mobile: this.mobile,
          tipoApp: this.tipoApp,
        });
    } else Object.assign(request.data);

    return request;
  }

  response(response) {
    if (response.data) {
      if (response.data.contaInvalida == true || response.data.alteraSenha == true) {
        document.querySelector('golfleet-loader').toggle(false);
        this.authServices.setLogout();
        return response;
      }
      if (response.data.atualizacaoDisponivel)
        this.$ngRedux.dispatch({
          type: 'APP_HAS_UPDATE',
          data: {
            hasRequiredUpdate: !!response.data.atualizacaoObrigatoria,
          },
        });
    }

    const { locationService } = this.$state.router;
    const sessionNeedUserAccept = this.session.privacyPolicyStatus?.needUserAccept;
    const responseNeedUserAccept = response.data.privacyPolicyUserStatus?.needUserAccept;

    if (
      locationService.$location.$$path !== '/login' &&
      sessionNeedUserAccept !== responseNeedUserAccept
    ) {
      this.$ngRedux.dispatch({
        type: 'APP_HAS_PRIVACY_POLICY_UPDATE',
        data: {
          privacyPolicyStatus: response.data.privacyPolicyUserStatus,
        },
      });
    }

    window.Sentry.configureScope(scope => {
      scope.setExtra('requestResponse', response);
    });

    return response;
  }

  responseError(rejection) {
    if (rejection.status == 401) {
      document.querySelector('golfleet-loader').toggle(false);
      this.authServices.setLogin();
    }
    return this.$q.reject(rejection);
  }
}

app.factory('authInterceptorService', AuthInterceptor);
