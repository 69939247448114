import angular from 'angular';

import template from './golfleet-popup.html';
import './golfleet-popup.scss';

class GolfleetPopupController {
  static get $inject() {
    return ['$element', '$timeout', '$scope'];
  }

  constructor($element, $timeout, $scope) {
    Object.assign(this, { $: $element[0], $timeout, $scope });

    [this.containerSlot] = this.$.children;
    this.clickEndEvent = this._clickEndEvent.bind(this);
    this.queryMobile = window.matchMedia('(max-width: 425px)');
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });

    if (this.outClose) {
      this.$.addEventListener('click', this.clickEndEvent);
    }
  }

  $onDestroy() {
    this.$.removeAttribute('open');

    if (this.outClose) {
      this.$.removeEventListener('click', this.clickEndEvent);
    }
  }
  // #endregion Lifecycle

  // #region Public
  async toggle() {
    if (!this.$.hasAttribute('open')) {
      this.$.setAttribute('open', '');

      await Promise.allSettled(
        this.$.querySelector('.containerSlot')
          .getAnimations()
          .map(animation => animation.finished),
      );

      document.querySelector('body').style.overflow = 'hidden';
    } else {
      this.$.removeAttribute('open');

      await Promise.allSettled(
        this.$.querySelector('.containerSlot')
          .getAnimations()
          .map(animation => animation.finished),
      );

      document.querySelector('body').style.overflow = null;

      if (this.reaction) {
        this.reaction();
      }
    }
  }
  // #endregion Public

  // #region Private
  _clickEndEvent(event) {
    if (event.target === this.$) this.toggle();
  }
  // #endregion Private
}

class GolfleetPopup {
  constructor() {
    this.template = template;
    this.bindings = {
      outClose: '=?',
      reaction: '&?',
    };
    this.transclude = {
      container: '?containerSlot',
    };
    this.controller = GolfleetPopupController;
  }
}

angular.module('golfleet-popup', []).component('golfleetPopup', new GolfleetPopup());
