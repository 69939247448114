class FilterCondition {
  constructor(id, field, value = [], preset = null) {
    this.id = id;
    this.field = field;
    this.value = this._castAsArray(value);
    this.preset = preset;
  }

  _castAsArray(value) {
    return Array.isArray(value) ? value : [value];
  }
}

// eslint-disable-next-line no-unused-vars
class GolfleetFilterController {
  static get $inject() {
    return ['$element', '$scope', 'filterServices'];
  }

  constructor($element, $scope, filterServices) {
    Object.assign(this, { $: $element[0], $scope, filterServices });

    this._search = '';
    this._toggleAll = false;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setFilterValue: this.setFilterValue.bind(this),
    });

    this.description = this.description || 'Carregando...';

    if (!this.requestApi) {
      this._initializeFilter();
    } else this._getOptions();
  }

  $onDestroy() {}
  /* */

  /* Public */
  toggle() {
    document.querySelector('golfleet-filter-menu').toggleFilter(this.filterId);
  }

  setFilterValue(value) {
    this.condition = new FilterCondition(this.id, this.field, value);
  }
  /* */

  /* Private */
  _initializeFilter() {
    if (this.condition)
      this._selectOption(
        this.options.filter(option => this.condition.value.includes(option.value)),
      );
    else if (this.default)
      this._selectOption(this.options.filter(option => this.default.includes(option.value)));
    else {
      this.setFilterValue([]);
      this._formatDescription([]);
    }
  }

  _changeActiveView(activeView) {
    this.activeView = activeView;
  }

  _formatDescription(selectedOptions) {
    if (!Array.isArray(selectedOptions)) return;
    if (selectedOptions.length == 0)
      this.description = this._formatDescriptionWords(`Qualquer ${this.singular}`);
    else if (selectedOptions.length == 1)
      this.description = this._formatDescriptionWords(
        `Apenas ${this._applyFormatter(selectedOptions[0].description)}`,
      );
    else
      this.description = this._formatDescriptionWords(`${selectedOptions.length} ${this.plural}`);
  }

  _applyFormatter(description) {
    if (typeof this.formatter === 'undefined') return description;
    return `${this.formatter}${description.split(' ').join(` ${this.formatter}`)}`;
  }

  _formatDescriptionWords(str) {
    const formatWord = index => (index == 0 ? 'toUpperCase' : 'toLowerCase');
    return str.split(' ').reduce((acc, word, index) => {
      if (
        !word ||
        ((word[0] == '^' || word[0] == '!') && !word[1]) ||
        ((word[1] == '^' || word[1] == '!') && !word[2])
      )
        return acc;
      if (word[0] == '!' && word[1] == '!') return `${acc} ${word.slice(2)}`;
      if (word[0] == '^' && word[1] == '^')
        return `${acc} ${word[2][formatWord(0)]()}${word.slice(3)[formatWord(0)]()}`;
      if (word[0] == '^')
        return `${acc} ${word[1][formatWord(0)]()}${word.slice(2)[formatWord(1)]()}`;
      return `${acc} ${word[0][formatWord(index)]()}${word.slice(1)[formatWord(1)]()}`;
    }, '');
  }

  _selectOption(selectOptions) {
    const filterById = (options, id) => options.filter(option => option.id == id);
    this.options = this.options.map(option =>
      filterById(selectOptions, option.id)[0]
        ? Object.assign(option, { selected: !option.selected })
        : option,
    );
    const selectedOptions = this.options.filter(option => option.selected);
    this.setFilterValue(selectedOptions.map(option => option.value));
    this._verifyToggleAll();
    this._formatDescription(selectedOptions);
  }

  _toggleAllOptions() {
    this._toggleAll = !this._toggleAll;
    this.options = this.options.map(option =>
      this._stringIncludes(option, this._search)
        ? Object.assign(option, { selected: this._toggleAll })
        : option,
    );
    const selectedOptions = this.options.filter(option => option.selected);
    this.setFilterValue(selectedOptions.map(option => option.value));
    this._formatDescription(selectedOptions);
  }

  _getOptions() {
    return this.filterServices
      .getFilterOptions({ ...this.requestData }, this.requestApi)
      .then(
        data => {
          this.options = Object.assign([], data);
          return this.options;
        },
        () => {
          this.options = [];
          return this.options;
        },
      )
      .finally(() => {
        this._initializeFilter();
      });
  }

  _verifyToggleAll() {
    const filteredOptions = this.options.filter(option => this._stringIncludes(option));
    const filteredSelectedOptions = filteredOptions.filter(option => option.selected);
    this._toggleAll =
      filteredOptions.length > 0 && filteredSelectedOptions.length == filteredOptions.length;
  }

  _stringIncludes(str) {
    return this._searchFilterOption(str);
  }

  _searchFilterOption(value) {
    if (!this._search) {
      return true;
    }
    return new RegExp(
      this._search
        .toLocaleLowerCase()
        .replace(/[*${}()|[\]\\]/g, '\\$&')
        .replace(/^( *, *)+|( *, *)+$/g, '')
        .replace(/( *, *)+/g, '|'),
    ).test(value.description.toLocaleLowerCase());
  }

  _filterIsOpened() {
    return this.$.hasAttribute('open');
  }
  /* */

  /* Observers */
  _searchChanged() {
    this._verifyToggleAll();
  }
}

// eslint-disable-next-line no-unused-vars
class GolfleetFilter {
  constructor() {
    this.bindings = {
      filterId: '=?',
      filterKey: '=?',
      field: '=?',
      icon: '=?',
      formatter: '=?',
      plural: '=?',
      singular: '=?',
      sufix: '=?',
      prefix: '=?',
      inputType: '=?',
      description: '=?',
      visible: '=?',
      historicalData: '=?',
      condition: '=?',
      default: '=?',
      options: '=?',
      requestApi: '=?',
      requestData: '=?',
      activeView: '=?',
    };
  }
}

function GolfleetFilterDataKey(filter) {
  if (typeof filter.field === 'string') {
    return `${filter.field}`;
  }
  return filter.filters
    ? `${filter.type}-${Object.keys(filter.filters).join('-')}`
    : `${filter.type}-${Object.values(filter.field).join('-')}`;
}

export { FilterCondition, GolfleetFilterController, GolfleetFilter, GolfleetFilterDataKey };
