/* eslint-disable no-restricted-syntax */
import angular from 'angular';
import '../../helpers/is-iframe/is-iframe';

import { GolfleetMapHereProvider } from './providers/here/golfleet-map.here-provider';

import template from './golfleet-map.html';

class GolfleetMapController {
  static get $inject() {
    return ['$element', '$ngRedux,', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    Object.assign(this, { $: $element[0], $ngRedux, $scope, $http, urlApi });

    this.mapConfigs = { here: {}, leaflet: {} };

    this.mapConfigs.here = {
      app_id: 'Iajt61WMPoEvbbMdvMH8',
      app_code: 'Z7ncDhO8GxIIHGGDmzCkWQ',
      useHTTPS: true,
    };

    this.$.setAttribute('provider', 'HERE');

    this.provider = new GolfleetMapHereProvider(this, $element, $ngRedux, $scope, $http, urlApi);

    this.resizeObserver = new ResizeObserver(this.__onResize.bind(this));
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      awaitRender: this.awaitRender.bind(this),
      getBounds: this.getBounds.bind(this),
      resizeMap: this.resizeMap.bind(this),
      fitBounds: this.fitBounds.bind(this),
      fitLayers: this.fitLayers.bind(this),
      removeLayers: this.removeLayers.bind(this),
      renderDataset: this.renderDataset.bind(this),
      openPopup: this.openPopup.bind(this),
    });

    this.provider.$onInit();

    this.resizeObserver.observe(this.$);
  }

  $onDestroy() {
    this.provider.$onDestroy();
    this.resizeObserver.unobserve(this.$);
  }
  /* */

  /* Public */
  async awaitRender() {
    return this.provider.awaitRender();
  }

  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
  }) {
    return this.provider.renderDataset({ dataset, layerName, type, useCluster, clusterColor });
  }

  removeLayers(layerNameList = ['default']) {
    this.provider.removeLayers(layerNameList);
  }

  resizeMap() {
    this.provider.resizeMap();
  }

  fitBounds(bounds) {
    this.provider.fitBounds(bounds);
  }

  fitLayers(layerNameList = ['default'], heatLayerName) {
    this.provider.fitLayers(layerNameList, heatLayerName);
  }

  getBounds(layerNameList, heatLayerName) {
    return this.provider.getBounds(layerNameList, heatLayerName);
  }

  openPopup(index = 0, layer = 'default') {
    this.provider.openPopup(index, layer);
  }

  enterFullscreen(fullscreenExitCallback) {
    this.provider.enterFullscreen(fullscreenExitCallback);
  }

  exitFullscreen() {
    this.provider.exitFullscreen();
  }
  /* */

  /* Observers */
  __onResize() {
    if (this.__onResizeTimeout) {
      clearTimeout(this.__onResizeTimeout);
    }

    this.__onResizeTimeout = setTimeout(() => {
      requestAnimationFrame(() => {
        this.resizeMap();

        clearTimeout(this.__onResizeTimeout);
      });
    }, 300);
  }
  /* */
}

class GolfleetMap {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.provider = GolfleetMapController;
  }
}

angular.module('golfleet-map', []).component('golfleetMap', new GolfleetMap());

export { GolfleetMapController };
