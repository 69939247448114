import angular from 'angular';
import 'ng-redux';
import moment from 'moment/src/moment';

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import Regression from 'highcharts-regression';

import { GolfleetReportController } from '../golfleet-report/golfleet-report';

import template from './golfleet-chart-report.html';
import './golfleet-chart-report.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);
Regression(Highcharts);
class GolfleetChartReportController extends GolfleetReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
    );
    Object.assign(this, {
      $: $element[0],
      $scope,
      $rootScope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
    });

    this.showFormatSelector = false;
    this.formatSelected = 'km';
    this.colors = [
      '#7cb5ec',
      '#434348',
      '#90ed7d',
      '#f7a35c',
      '#8085e9',
      '#f29d00',
      '#4aae4e',
      '#fe5620',
      '#007aff',
      '#d19977',
      '#d9456c',
      '#12307d',
      '#ffdea6',
      '#124bcc',
      '#937fb3',
      '#0c4e6a',
    ];

    this.firtsAnimation = true;
    this.status = 0;

    $ngRedux.connect(store =>
      Object({
        /* Session Storage */
        refreshFilter: store.session.refreshFilter,
        modules: store.session.modules,
      }),
    )(this);
  }

  $onInit() {
    if (this.currentState.routeLink == 'travelReport') {
      this.showFormatSelector = true;
      this.colors = [
        '#845EC2',
        '#4B4453',
        '#B0A8B9',
        '#C34A36',
        '#FF8066',
        '#0081CF',
        '#e91c61',
        '#af2562',
        '#ff99bb',
      ];
    }
    this.showRanking = this.modules.find(m => m === 'DRIVER_RANKING');
    this.$rootScope.$on('updateModules', (event, data) => {
      this.showRanking = data.find(m => m === 'DRIVER_RANKING');
    });
    this._getData();
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  _getData() {
    this.status = 0;
    this.$http({
      url: `${this.urlApi}/${this.stateConfig.getDataMethod}`,
      method: 'POST',
    }).then(
      success => {
        if (success.status && success.status != 200) {
          return;
        }
        if (success.data.data) {
          this.months = success.data.data.months;
          this.monthsInHour = success.data.data.monthsInHour;
          this.selectedMonth = this.months[this.months.length - 1];
          this.status = 1;
          setTimeout(() => {
            this._createChart();
          }, 50);
        } else {
          this.status = 3;
        }
      },
      () => {
        this.status = 2;
      },
    );
  }

  _toDate(date) {
    return moment.utc(date).add(3, 'hours')._d;
  }

  _changeformatSelected(format) {
    this.formatSelected = format;
    if (format == 'hs') {
      this.selectedMonth = this.monthsInHour.find(
        m => this._toDate(m.date).getMonth() === this._toDate(this.selectedMonth.date).getMonth(),
      );
    } else {
      this.selectedMonth = this.months.find(
        m => this._toDate(m.date).getMonth() === this._toDate(this.selectedMonth.date).getMonth(),
      );
    }
    this.firtsAnimation = true;
    this._createChart();
  }

  _createChart() {
    const _this = this;
    this.chart = Highcharts.chart('chart', {
      chart: {
        type: 'column',
        margin: [0, 0, 30, 0],
        style: {
          fontFamily: 'Ubuntu',
          fontWeight: 700,
        },
        borderWidth: 0,
        events: {
          load() {
            const [xAxis] = this.xAxis;
            const [series] = this.series;
            for (const point of series.points) {
              xAxis.ticks[point.index].label.element.addEventListener('click', evt => {
                evt.stopPropagation();
                _this._selectMonthFromIndex(point.index);
                _this._requestUpdate();
              });
            }
          },
        },
      },

      title: {
        text: null,
      },

      xAxis: {
        categories: this._getChartCategories(),
        plotBands: this._getChartPlotBands(),
        lineWidth: 0,
        labels: {
          useHTML: true,
          style: {
            color: '#9d9d9d',
          },
          formatter: e => this._getHtmlCategory(e.value),
        },
      },

      yAxis: {
        visible: false,
      },

      legend: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      exporting: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          borderWidth: 0,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          events: {
            click: e => {
              this._selectMonth(e.point.category);
              this._requestUpdate();
            },
          },
          animation: this.firtsAnimation,
        },
      },
      series: this._getChartSeries(),
    });
    this.firtsAnimation = false;
  }

  _getChartCategories() {
    const categories = [];
    this.months.forEach((month, i) => {
      categories[i] = new Date(month.date)
        .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', month: 'short' })
        .split('.')[0]
        .toUpperCase();
    });
    return categories;
  }

  _getHtmlCategory(month) {
    const style =
      new Date(this.selectedMonth.date)
        .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', month: 'short' })
        .split('.')[0]
        .toUpperCase() === month
        ? 'style="color: var(--basic-text-color)"'
        : '';
    return `<span id="${month}" ${style}>${month}</span>`;
  }

  _selectMonth(monthAcronym) {
    if (this.formatSelected === 'km') {
      this.selectedMonth = this.months.find(
        e =>
          new Date(e.date)
            .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', month: 'short' })
            .split('.')[0]
            .toUpperCase() === monthAcronym,
      );
    } else {
      this.selectedMonth = this.monthsInHour.find(
        e =>
          new Date(e.date)
            .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', month: 'short' })
            .split('.')[0]
            .toUpperCase() === monthAcronym,
      );
    }
    this._createChart();
  }

  _selectMonthFromIndex(i) {
    this._selectMonth(this._getChartCategories()[i]);
  }

  _getChartPlotBands() {
    const plotBands = [];
    const tempMonths = this.formatSelected === 'km' ? this.months : this.monthsInHour;
    tempMonths.forEach((month, i) => {
      const color = i % 2 == 0 ? '#d4d4d4' : '#e1e1e1';
      plotBands.push({
        color: this.selectedMonth.date == month.date ? '#fdfdfd' : color,
        from: -0.5 + i,
        to: 0.5 + i,
        events: {
          click: () => {
            this._selectMonth(
              new Date(month.date)
                .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', month: 'short' })
                .split('.')[0]
                .toUpperCase(),
            );
            this._requestUpdate();
          },
        },
      });
    });
    return plotBands;
  }

  _getChartSeries() {
    const series = [];
    const tempMonths = this.formatSelected === 'km' ? this.months : this.monthsInHour;
    tempMonths.forEach((month, i) => {
      month.items.forEach((item, j) => {
        if (!series[j]) {
          series[j] = {
            color: this.colors[j],
            data: [],
          };
        }
        series[j].data[i] = item.count;
      });
    });
    return series;
  }

  _getDateString() {
    if (this.selectedMonth) {
      const date = new Date(this.selectedMonth.date);
      return `${date.toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
        month: 'long',
      })} de ${date.getFullYear()}`;
    }
    return '';
  }

  _getBackgroundColor(index) {
    return {
      'background-color': this.colors[index],
    };
  }

  _convertTime(hours) {
    if (hours == 0) return '0';
    const dias = parseInt(hours / 24);
    hours -= dias * 24;
    const horas = parseInt(hours);
    const segundos = (hours - horas) * 60;
    let str = '';
    str += dias != 0 ? `${Math.round(dias)}d` : '';
    str += horas != 0 ? `${Math.round(horas)}h` : '';
    str += segundos != 0 ? `${Math.round(segundos)}m` : '';
    return str;
  }
}

class GolfleetChartReport {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetChartReportController;
  }
}

angular
  .module('golfleet-chart-report', [
    'ngRedux',
    'ng-tippy',
    'golfleet-toolbar',
    'golfleet-loader',
    'golfleet-toast',
  ])
  .component('golfleetChartReport', new GolfleetChartReport());
