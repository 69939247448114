import angular from 'angular';

import { GolfleetMapUtilizationHereProvider } from './providers/here/golfleet-map-utilization.here-provider';
import { GolfleetMapController } from '../golfleet-map/golfleet-map';

import template from './golfleet-map-utilization.html';

class GolfleetMapUtilizationController extends GolfleetMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    this.provider = new GolfleetMapUtilizationHereProvider(
      this,
      $element,
      $ngRedux,
      $rootScope,
      $scope,
      $http,
      urlApi,
    );
  }

  $onInit() {
    super.$onInit();
  }

  /* Public */
  removeLayers(layerNameList = ['default']) {
    this.provider.removeLayers(layerNameList);
  }

  toggleTimelineControl(show) {
    this.provider.toggleTimelineControl(show);
  }

  setTimelineDataset({ markers, linestrings }) {
    this.provider.setTimelineDataset({ markers, linestrings });
  }

  updateTimelinePosition(evtParams) {
    this.provider.updateTimelinePosition(evtParams);
  }
  /**/

  /* Private */
  _toggleRouteTimeline() {
    return this.provider._toggleRouteTimeline();
  }

  _endRouteTimeline() {
    this.provider._endRouteTimeline();
  }

  _renderRouteTimeline() {
    return this.provider._renderRouteTimeline();
  }
  /**/
}

class GolfleetMapUtilization {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetMapUtilizationController;
  }
}

angular
  .module('golfleet-map-utilization', ['golfleet-map'])
  .component('golfleetMapUtilization', new GolfleetMapUtilization());
