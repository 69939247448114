import angular from 'angular';

import '../golfleet-dropdown/golfleet-dropdown';
import '../golfleet-header-drag-n-drop/golfleet-header-drag-n-drop';
import '../golfleet-popup/golfleet-popup';

import template from './golfleet-header-selector.html';
import './golfleet-header-selector.scss';

class GolfleetHeaderSelectorController {
  static get $inject() {
    return ['$element', '$scope', '$timeout'];
  }

  constructor($element, $scope, $timeout) {
    Object.assign(this, { $: $element[0], $scope, $timeout });

    this.gridHeaders = [];
    this.activeHeaders = [];
    this.selectedCategory = '';
    this.gridHeadersCategories = [];

    this.$scope.$watch(() => this.gridHeaders, this.__gridHeadersChanged.bind(this));
    this.$scope.$watch(
      () => this.gridHeadersCategories,
      this.__gridHeadersCategoriesChanged.bind(this),
    );
  }

  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });
  }

  toggle() {
    if (window.screen.width > 425) {
      this.$.querySelector('golfleet-dropdown')?.toggle();
    } else {
      this._selectedHeadersPopupToggle();
    }
  }

  _selectCategory(category) {
    this.selectedCategory = category;
    this.__setDroodownContent();
  }

  _toggleHeader(field, show) {
    this.gridHeaders.forEach(header => {
      if (header.title) {
        header.show = header.field === field ? show : header.show;
      }
    });

    if (show) {
      this.activeHeaders = this.gridHeaders.filter(header => header.show && header.title);
      this.__setDroodownContent();
    } else {
      this.activeHeaders = this.activeHeaders.filter(
        header => header.field !== field && header.title,
      );
    }

    this.$scope.$emit('UPDATE_ROUTE');
  }

  _placeholderItemList(list = [], filter) {
    const listLength = (
      !filter
        ? list
        : list.filter(item => {
            let shouldReturn = true;
            Object.keys(filter).forEach(key => {
              if (item[key] != filter[key]) shouldReturn = false;
            });
            return shouldReturn;
          })
    ).length;

    if (listLength >= 8) return [];

    const placeholderList = [];
    for (let i = 0; i < 8 - listLength; i++) {
      placeholderList.push(i);
    }
    return placeholderList;
  }

  _toggleAllByCategory() {
    const show = this.gridHeaders.reduce(
      (acc, header) =>
        header.category === this.selectedCategory && header.show === false && header.title
          ? true
          : acc,
      false,
    );

    this.gridHeaders.forEach(header => {
      header.show =
        !header.fixed && header.title && header.category == this.selectedCategory
          ? show
          : header.show;
    });

    if (show) {
      const activeMap = this.activeHeaders.reduce((acc, header, index) => {
        acc[header.field] = index;
        return acc;
      }, {});

      this.activeHeaders = [
        ...this.activeHeaders,
        ...this.gridHeaders.filter(
          header => header.show && !(header.field in activeMap) && header.title,
        ),
      ];

      this.__setDroodownContent();
    } else {
      this.activeHeaders = this.activeHeaders.filter(
        header => header.title && (header.fixed || header.category !== this.selectedCategory),
      );
    }

    this.$scope.$emit('UPDATE_ROUTE');
  }

  _removeAllActives() {
    this.gridHeaders.forEach(header => {
      header.show = header.fixed;
    });
    this.activeHeaders = this.activeHeaders.filter(header => header.fixed && header.title);
  }

  _selectAllIcon() {
    if (this.gridHeaders) {
      const categoryHeaders = this.gridHeaders.filter(
        header => header.fixed === false && header.category === this.selectedCategory,
      );

      const selectedCategoryHeaderLength = categoryHeaders.filter(header => header.show).length;

      if (selectedCategoryHeaderLength === 0) {
        return 'check_box_outline_blank';
      }
      if (selectedCategoryHeaderLength === categoryHeaders.length) {
        return 'check_box_outline';
      }
      return 'indeterminate_check_box';
    }

    return 'check_box_outline_blank';
  }

  _selectActivesIcon() {
    if (this.gridHeaders) {
      const headers = this.gridHeaders.filter(header => header.fixed === false);
      const selectedHeaderLength = headers.filter(header => header.show).length;

      if (selectedHeaderLength === 0) {
        return 'check_box_outline_blank';
      }

      return 'check_box_outline';
    }

    return 'check_box_outline_blank';
  }

  _headerSelectorClick(header, selector) {
    header.selectors.forEach(item => {
      if (item.selector === selector.selector) item.selected = !selector.selected;
      else item.selected = false;
    });
  }

  _headerTitle(title) {
    if (!Array.isArray(title)) return title;

    return title.join(' ');
  }

  _selectedHeadersPopupToggle() {
    this.$.querySelector('#golfleet-popup-headers').toggle();
  }

  _popupEditActiveHeaders() {
    const contentElement = this.$.querySelector('.golfleet-popup-headers-content');
    if (contentElement.hasAttribute('editing')) {
      contentElement.removeAttribute('editing');
    } else {
      contentElement.setAttribute('editing', '');
    }
  }

  __onToggleHeader() {
    this.activeHeaders = this.gridHeaders.filter(header => header.show && header.title);
  }

  __setDroodownContent() {
    this.$timeout(() => {
      this.$timeout(() => {
        this.$.querySelectorAll('list-slot *:not([dropdown-content])').forEach(element => {
          element.setAttribute('dropdown-content', '');
        });
      });
    });
  }

  __gridHeadersChanged(newValue = [], oldValue = []) {
    if (!newValue || newValue.length === 0) return;

    let shouldUpdate = this.selectedCategory === '';

    if (!shouldUpdate && oldValue && oldValue.length > 0) {
      const newValueFields = newValue.map(p => p.field).sort();
      const oldValueFields = oldValue.map(p => p.field).sort();

      shouldUpdate = JSON.stringify(newValueFields) === JSON.stringify(oldValueFields);
    }

    if (shouldUpdate) {
      this.activeHeaders = newValue.filter(header => header.show && header.title);
    }
  }

  __gridHeadersCategoriesChanged(newValue = [], oldValue = []) {
    if (!newValue || newValue.length === 0) return;

    let shouldUpdate = this.selectedCategory === '';

    if (!shouldUpdate && oldValue && oldValue.length > 0) {
      const newValueFields = newValue.map(p => p.field).sort();
      const oldValueFields = oldValue.map(p => p.field).sort();

      shouldUpdate = JSON.stringify(newValueFields) === JSON.stringify(oldValueFields);
    }

    if (shouldUpdate) {
      this.selectedCategory = newValue[0].category;
    }
  }
}

class GolfleetHeaderSelector {
  constructor() {
    this.template = template;
    this.bindings = {
      gridHeaders: '=?',
      headerCategories: '=?',
      gridHeadersCategories: '=?',
    };
    this.controller = GolfleetHeaderSelectorController;
  }
}

angular
  .module('golfleet-header-selector', [
    'golfleet-dropdown',
    'golfleet-header-drag-n-drop',
    'golfleet-popup',
  ])
  .component('golfleetHeaderSelector', new GolfleetHeaderSelector());
