class ToolbarConfig {
  constructor(params = {}) {
    const { saveConfig, viewsConfig, exportConfig, actionConfig, autoSendConfig } = params;

    this.viewsConfig = viewsConfig;
    this.exportConfig = exportConfig;
    this.actionConfig = actionConfig;
    this.saveConfig = saveConfig;
    this.autoSendConfig = autoSendConfig;
  }
}

class GridConfig {
  constructor(params = {}) {
    const {
      page = 1,
      dataset,
      lastPage = 0,
      pageSize = 10,
      gridTotal,
      sortField,
      mobileMode = 'default',
      gridMongoId,
      activeField,
      gridHeaders,
      sortDirection,
      backPagination = false,
      hasRowSelection = false,
      descriptionField,
    } = params;

    this.page = page;
    this.dataset = dataset;
    this.lastPage = lastPage;
    this.pageSize = pageSize;
    this.gridTotal = gridTotal;
    this.sortField = sortField;
    this.mobileMode = mobileMode;
    this.gridHeaders = gridHeaders;
    this.activeField = activeField;
    this.gridMongoId = gridMongoId;
    this.sortDirection = sortDirection;
    this.backPagination = backPagination;
    this.hasRowSelection = hasRowSelection;
    this.descriptionField = descriptionField;
  }
}

class DashStateConfig {
  constructor(params = {}) {
    const { screenName, toolbarName, filterConfig = [], filterConditions = [] } = params;

    this.navigation = { date: [], hierarchy: {} };
    this.screenName = screenName;
    this.toolbarName = toolbarName;
    this.filterConfig = filterConfig;
    this.toolbarConfig = new ToolbarConfig();
    this.filterConditions = filterConditions;
  }
}

class ReportStateConfig {
  constructor(params = {}) {
    const {
      type,
      viewMode = 'grid',
      gridName,
      screenName,
      toolbarName,
      filterConfig = [],
      getDataMethod,
      backPagination,
      getDataFilters,
      hasRowSelection,
      filterConditions = [],
      getDataFixedParams,
    } = params;

    this.type = type;
    this.viewMode = viewMode;
    this.gridName = gridName;
    this.navigation = { date: [], hierarchy: {} };
    this.gridConfig = new GridConfig({ backPagination, hasRowSelection });
    this.screenName = screenName;
    this.toolbarName = toolbarName;
    this.filterConfig = filterConfig;
    this.toolbarConfig = new ToolbarConfig();
    this.getDataMethod = getDataMethod;
    this.getDataFilters = getDataFilters;
    this.filterConditions = filterConditions;
    this.getDataFixedParams = getDataFixedParams;
  }
}

export { ToolbarConfig, DashStateConfig, ReportStateConfig };
