import angular from 'angular';
import 'ng-redux';

import template from './golfleet-loader.html';
import './golfleet-loader.scss';

class GolfleetLoaderController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });
  }

  $onDestroy() {}
  /* */

  /* Public */
  toggle(show) {
    if (show) this.$.setAttribute('show', '');
    else this.$.removeAttribute('show');
  }
  /* */
}

class GolfleetLoader {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetLoaderController;
  }
}

angular.module('golfleet-loader', ['ngRedux']).component('golfleetLoader', new GolfleetLoader());
